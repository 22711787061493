<template>
  <div class="table_wrapper">
    <v-data-table
      :headers="headers"
      :items="data"
      :items-per-page="5"
      class="elevation-1"
    ></v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  name: "Visits",
  data() {
    return {
      data: [],
      token: localStorage.getItem("authToken"),

      headers: [
        {
          text: "Referring Site Name",
          align: "start",
          sortable: false,
          value: "col1",
        },
        { text: "Visits IP", value: "col2", sortable: false },
        { text: "Reffering URL", value: "col3", sortable: false },
        { text: "Visited Page URL", value: "col4", sortable: false },
        { text: "Keyword", value: "col5", sortable: false },
        { text: "TimeZone", value: "col6", sortable: false },
        { text: "Country", value: "col7", sortable: false },
        { text: "Region", value: "col8", sortable: false },
        { text: "Visit Date", value: "col9", sortable: false },
        { text: "Device Lang", value: "col10", sortable: false },
        { text: "Site Lang", value: "col11", sortable: false },
        { text: "Is Mobile", value: "col12", sortable: false },
        { text: "OS", value: "col13", sortable: false },
      ],
    };
  },
  methods: {
    parseLink: function(link) {
      return link.substr(0, 50) + "...";
    },
    getList: function() {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "reports/visit-reports?api_token=" +
            this.token
        )
        .then((res) => {
          res.data.data.data.forEach((visit) => {
            const key = {
              col1: visit.referrer_site_name,
              col2: visit.ip,
              col3: visit.referrer_site_url,
              col4: this.parseLink(visit.redirected_url),
              col5: visit.gowawe_search_keyword,
              col6: visit.visit_timezone,
              col7: visit.country,
              col8: visit.region,
              col9: visit.created_at,
              col10: visit.device_lang,
              col11: visit.site_lang,
              col12: visit.is_mobile == 1 ? "Mobile" : "Desktop",
              col13: visit.os,
            };
            this.data.push(key);
          });
        });
    },
  },
  mounted() {
    this.getList();
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
};
</script>
